import React from 'react';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const darkTheme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#000',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#11cb5f',
    },
  },
});

export default function Theme({children}) {
  return <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>;
}